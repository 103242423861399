






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    customTitle: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    }
  }
})
